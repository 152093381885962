import React from 'react';
import { graphql } from 'gatsby';
import WreathList from '../components/WreathList';
import SEO from '../components/SEO';

export default function KraenzePage({ data }) {
  const wreaths = data.wreaths.nodes;
  return (
    <>
      <SEO title="Kränze" />
      <div className="main">
        <WreathList wreaths={wreaths} />
      </div>
    </>
  );
}

export const query = graphql`
  query PizzaQuery {
    wreaths: allSanityWreath {
      nodes {
        name
        id
        slug {
          current
        }
        size {
          id
          name
        }
        candles {
          id
          name
        }
        style {
          id
          name
        }
        image {
          asset {
            fixed(width: 400, height: 400) {
              ...GatsbySanityImageFixed
            }
            fluid(maxWidth: 400, maxHeight: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
