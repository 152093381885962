import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const WreathGridStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
  /* grid-auto-rows: auto auto 300px; */
`;

const WreathStyles = styled.div`
  display: grid;
  @supports not (grid-template-rows: subgrid) {
    --rows: auto auto 1fr;
  }
  /* Take row sizing not from wreathStyles div, but from the WreathGridStyles grid  */
  grid-template-rows: var(--rows, subgrid);
  grid-row: span 3;
  grid-gap: 1rem;
  h2,
  p {
    margin: 0;
    font-family: 'Open Sans';
  }
  p {
    font-size: 1rem;
  }
`;

function SingleWreath({ wreath }) {
  return (
    <WreathStyles>
      {/* <Link to={`/wreath/${wreath.slug.current}`}> */}
      {/* <h2>
        <span className="mark">{wreath.name}</span>
      </h2> */}
      {/* </Link> */}
      <Img fluid={wreath.image.asset.fluid} alt={wreath.name} />

      <p>{`${wreath.size[0]?.name ? `${wreath.size[0].name}, ` : ''} 
        ${wreath.candles[0]?.name ? `Kerzen: ${wreath.candles[0].name}, ` : ''} 
      ${wreath.style[0]?.name ? `${wreath.style[0].name} ` : ''}`}</p>
    </WreathStyles>
  );
}

export default function WreathList({ wreaths }) {
  return (
    <WreathGridStyles>
      {wreaths.map((wreath) => (
        <SingleWreath key={wreath.id} wreath={wreath} />
      ))}
    </WreathGridStyles>
  );
}
